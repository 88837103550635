import React from "react";

function Incidentes() {
  return <>
    <iframe src="https://jf-oia.pt/pages/ocorrencias.html" width="100%" height="1100px" frameBorder="0" />
    <iframe src="https://jf-oia.pt/pages/app.html" width="100%" height="200px" frameBorder="0" />
  </>;
}

export default Incidentes;
